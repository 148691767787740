import React, { useContext, useState, createContext, useEffect } from 'react';
import JSCookie from 'js-cookie';

import { DEFAULT_LANG, LANG_LIST } from 'constants/translations';
import { LANG_PREFERENCE, COOKIE_NAME } from 'constants/cookie';
import { getLocaleInPath } from 'helpers/path';
import useTranslation from 'hooks/useTranslation';
import { getSpecificEvent } from 'apis/events';

import {
  EVENT_STATUS_UPCOMING,
  EVENT_STATUS_FINISHED,
  EVENT_STATUS_FEATURED
} from 'constants/event';
import { retrieveInitialEvents } from 'helpers/events';

const AppContext = createContext({});

const AppProvider = (props) => {
  const isCookieAllowed = JSCookie.get(COOKIE_NAME);
  const tLocale = getLocaleInPath(window.location.pathname);
  const locale = isCookieAllowed
    ? JSCookie.get(LANG_PREFERENCE)
    : LANG_LIST.includes(tLocale) && tLocale;
  const { getTranslation } = useTranslation();
  const [allUpcomingEvents, setAllUpcomingEvents] = useState([]);
  const [chatWee, setChatWee] = useState(null);
  const [allFinishedEvents, setAllFinishedEvents] = useState([]);
  const [allFeaturedEvents, setAllFeaturedEvents] = useState([]);
  const [upcomingTotalCount, setUpcomingTotalCount] = useState(0);
  const [finishedTotalCount, setFinishedTotalCount] = useState(0);
  const [currentEvent, setCurrentEvent] = useState();
  const [currentTheme, setCurrentTheme] = useState('tiketti');
  const [currentPlayerEvent, setCurrentPlayerEvent] = useState();
  const [currentPlayerEventID, setCurrentPlayerEventID] = useState(0);
  const [tempCookieState, setTempCookieState] = useState(true);
  const [hasValidatedTicket, setHasValidatedTicket] = useState(false);
  const [translations, setTranslations] = useState([]);
  const [language, setLanguage] = useState(locale || DEFAULT_LANG);

  useEffect(() => {
    const featuredEvents = async () => {
      try {
        const { rows } = await retrieveInitialEvents(
          EVENT_STATUS_FEATURED,
          language
        );
        setAllFeaturedEvents(rows);
      } catch {
        console.log('Failed to retrieve events');
      }
    };
    featuredEvents();
  }, [setAllFeaturedEvents, language]);

  useEffect(() => {
    const upcomingEvents = async () => {
      try {
        const { rows, count } = await retrieveInitialEvents(
          EVENT_STATUS_UPCOMING,
          language
        );
        setAllUpcomingEvents(rows);
        setUpcomingTotalCount(count);
      } catch {
        console.log('Failed to retrieve events');
      }
    };
    upcomingEvents();
  }, [setAllUpcomingEvents, language]);

  useEffect(() => {
    const finishedEvents = async () => {
      try {
        const { rows, count } = await retrieveInitialEvents(
          EVENT_STATUS_FINISHED,
          language
        );
        setAllFinishedEvents(rows);
        setFinishedTotalCount(count);
      } catch {
        console.log('Failed to retrieve events');
      }
    };
    finishedEvents();
  }, [setAllFinishedEvents, language]);

  useEffect(() => {
    const getTranslate = async () => {
      const aTranslation = await getTranslation(language);
      setTranslations(aTranslation);
      document.documentElement.setAttribute('lang', language);
      isCookieAllowed && JSCookie.set(LANG_PREFERENCE, language);
    };
    getTranslate();
  }, [language, getTranslation, isCookieAllowed]);

  const values = {
    currentEvent,
    setCurrentEvent,
    chatWee,
    setChatWee,
    currentPlayerEvent,
    setCurrentPlayerEvent,
    currentPlayerEventID, 
    setCurrentPlayerEventID,
    tempCookieState,
    setTempCookieState,
    translations,
    setTranslations,
    language,
    setLanguage,
    allUpcomingEvents,
    setAllUpcomingEvents,
    allFinishedEvents,
    setAllFinishedEvents,
    upcomingTotalCount,
    setUpcomingTotalCount,
    finishedTotalCount,
    setFinishedTotalCount,
    hasValidatedTicket,
    setHasValidatedTicket,
    allFeaturedEvents,
    setAllFeaturedEvents,
    currentTheme, 
    setCurrentTheme
  };

  return <AppContext.Provider value={values} {...props} />;
};

const useApp = () => useContext(AppContext);

export { AppProvider, useApp };
